import { Capacitor } from "@capacitor/core";
import { App } from "@capacitor/app";
import { Preferences } from "@capacitor/preferences";
import CustomUserDefaults from "../plugins/CustomUserDefaults";
import BroadcastBridge from "../plugins/Android/BroadcastBridge";
// services
import Constants from "./constants";
// types
import type { GatewayId, GatewayName, GatewayStatus } from "../types/gateway";

export const storeAppGroupKey = async (): Promise<void> => {
	const { id } = await App.getInfo();

	await Preferences.set({ key: "appGroup", value: `group.${id}` });
};

const getAppGroup = async (): Promise<string> => {
	const { value } = await Preferences.get({ key: "appGroup" });
	return value ?? "";
};

export const storeGatewayPreferences = async (gatewayId: GatewayId, gatewayName: GatewayName, gatewayStatus: GatewayStatus): Promise<void> => {
	const appGroup = await getAppGroup();
	await CustomUserDefaults.configure({
		group: appGroup,
	});

	await CustomUserDefaults.set({ key: "selected_gateway_id", value: gatewayId });
	await CustomUserDefaults.set({ key: "selected_gateway_name", value: gatewayName });
	await CustomUserDefaults.set({ key: "selected_gateway_status", value: gatewayStatus });

	if (Capacitor.getPlatform() === Constants.Platform.Android) {
		await BroadcastBridge.sendAppWidgetUpdateBroadcast();
	}
};

export const clearGatewayPreferences = async (): Promise<void> => {
	const appGroup = await getAppGroup();
	await CustomUserDefaults.configure({
		group: appGroup,
	});

	await CustomUserDefaults.remove({ key: "selected_gateway_id" });
	await CustomUserDefaults.remove({ key: "selected_gateway_name" });
	await CustomUserDefaults.remove({ key: "selected_gateway_status" });

	if (Capacitor.getPlatform() === Constants.Platform.Android) {
		await BroadcastBridge.sendAppWidgetUpdateBroadcast();
	}
};
