import BackgroundGeolocation from "@transistorsoft/capacitor-background-geolocation";
import { distance as getDistance } from "@turf/distance";
import { CapacitorHttp } from "@capacitor/core";
import { NativeLogin } from "../plugins/NativeLogin";
// hooks
import { getDeviceId } from "../hooks/useDeviceInfo";
// services
import Glient from "./glient";
import Gateways from "./gateways";
import { fetchDevicesFromGateway } from "./devices";
import Device from "./device";
import EpDevice from "./ep-device";
import DeviceType from "./device-type";
import ClusterConstants from "./cluster-constants";
import { getRestHeaders } from "./rest-headers";
import { getGeofenceAccuracyLevel } from "./geofenceSetup";
// types
import type { AuthorizationStatus, Geofence, State } from "@transistorsoft/capacitor-background-geolocation";
import type { Gateway, GatewayId } from "../types/gateway";
import type { DeviceId, DeviceObjs, DeviceObj } from "../types/device";
import type { CmdGatewayActionGeofenceGetRadius } from "../types/message";
import type { PhoneMode } from "../types/misc";

const getRadius = async (gatewayId: GatewayId): Promise<number | undefined> => {
	const cmd = {
		action: "gatewayAction",
		module: "geofence",
		function: "get_radius",
		params: [],
		gatewayId: gatewayId,
	} as const satisfies CmdGatewayActionGeofenceGetRadius;
	return new Promise((resolve, reject) => {
		Glient.send(cmd, (error, msg) => {
			if (!error && msg?.payload.status === "ok") {
				if (typeof msg.payload.data === "number") {
					resolve(msg.payload.data);
				} else {
					resolve(undefined);
				}
			} else {
				reject(error);
			}
		});
	});
};

const makeGeofence = async (gatewayId: GatewayId, latitude: number, longitude: number, radius: number | undefined = undefined): Promise<Geofence | undefined> => {
	if (radius === undefined) {
		try {
			radius = await getRadius(gatewayId);
		} catch (error) {
			console.warn("failed to get radius", error, gatewayId);
		}
	}

	if (radius) {
		return {
			identifier: gatewayId,
			radius: radius,
			latitude: latitude,
			longitude: longitude,
			notifyOnEntry: true,
			notifyOnExit: true,
		} as const satisfies Geofence;
	}

	return undefined;
};

const sendExitEvents = async (geofences: Array<Geofence>, url: string): Promise<void> => {
	try {
		// `BackgroundGeolocation.getCurrentPosition()` takes very long
		const { coords, timestamp } = await BackgroundGeolocation.getCurrentPosition({ persist: false });
		const initExitEventGeofs = geofences.filter(({ /*identifier, */radius, latitude, longitude }) => {
			if (radius === undefined || latitude === undefined || longitude === undefined) {
				return false;
			}
			const distance = getDistance([latitude, longitude], [coords.latitude, coords.longitude], { units: "meters" });
			return distance > radius;
		});
		if (initExitEventGeofs.length > 0) {
			const { accessToken } = await NativeLogin.getTokens();
			const deviceId = await getDeviceId();
			const restHeaders = await getRestHeaders();

			const tasks = initExitEventGeofs.map(({ identifier }) => (
				CapacitorHttp.post({
					url: url,
					data: {
						id: deviceId,
						gwId: identifier,
						loc: "EXIT",
						ts: timestamp,
					},
					headers: {
						"Authorization": `Bearer ${accessToken}`,
						"Content-Type": "application/json",
						"Origin": "http://localhost",
						...restHeaders,
					},
				})
			));
			const results = await Promise.allSettled(tasks);
			const errors = results.filter((result) => (result.status === "rejected")).map((result) => (result.reason));
			if (errors.length > 0) {
				console.error("new EXIT events exceptions:", errors);
			}
		}
	} catch (error) {
		console.error("Exception in sendExitEvents:", error);
	}
};

export const checkAndRequestGeolocationPermission = async (requestEnable: boolean = true): Promise<AuthorizationStatus> => {
	try {
		const { status } = await BackgroundGeolocation.getProviderState();
		if (requestEnable && ![BackgroundGeolocation.AUTHORIZATION_STATUS_WHEN_IN_USE, BackgroundGeolocation.AUTHORIZATION_STATUS_ALWAYS].includes(status)) {
			try {
				return await BackgroundGeolocation.requestPermission();
			} catch (authStatus) {
				console.warn("Geolocation-Permissions not granted", authStatus);
				return authStatus as AuthorizationStatus;
			}
		} else {
			return status;
		}
	} catch (error) {
		console.error("checkAndRequestGeolocationPermission error", error);
		return BackgroundGeolocation.AUTHORIZATION_STATUS_NOT_DETERMINED;
	}
};

const enableDisableGeofence = async (newGeofences: Array<Geofence>): Promise<void> => {
	const { enabled, url } = await BackgroundGeolocation.getState();
	let geofenceUrl = url;
	let hasGeofences = false;
	try {
		const geofences = await BackgroundGeolocation.getGeofences();
		hasGeofences = (geofences.length + newGeofences.length) > 0;
	} catch (error) {
		console.warn("Exception in 'BackgroundGeolocation.getGeofences'", error);
	}

	if (enabled && !hasGeofences) {
		await BackgroundGeolocation.stop();
	} else if (!enabled && hasGeofences) {
		const authorizationStatus = await checkAndRequestGeolocationPermission();

		if ([BackgroundGeolocation.AUTHORIZATION_STATUS_WHEN_IN_USE, BackgroundGeolocation.AUTHORIZATION_STATUS_ALWAYS].includes(authorizationStatus)) {
			// fallback, if url is undefined
			const { locationTracking } = await getGeofenceAccuracyLevel();
			let startGeofenceState: State;
			if (locationTracking) {
				startGeofenceState = await BackgroundGeolocation.start();
			} else {
				startGeofenceState = await BackgroundGeolocation.startGeofences();
			}
			if ((geofenceUrl === undefined || geofenceUrl.length === 0) && startGeofenceState.url) {
				geofenceUrl = startGeofenceState.url;
			}
		}
	}

	if (newGeofences.length > 0) {
		await BackgroundGeolocation.addGeofences(newGeofences);
	}

	const { status } = await BackgroundGeolocation.getProviderState();
	if (geofenceUrl && status === BackgroundGeolocation.AUTHORIZATION_STATUS_ALWAYS && newGeofences.length > 0) {
		await sendExitEvents(newGeofences, geofenceUrl);

		try {
			await BackgroundGeolocation.changePace(true);
		} catch {
			console.warn("enableDisableGeofence, could not changePace, error!");
		}
	}
};

const isGatewayGeofenceReady = (gateway?: Gateway): boolean => (
	Boolean(gateway && gateway.geofence_status && gateway.latitude && gateway.longitude)
);

const isDeviceGeofencePhoneModeRealtime = (device: DeviceObj): boolean => {
	const epDevices = device.eps.map((endpoint) => (EpDevice.getEpDeviceFromDeviceAndEpId(device, endpoint.endpoint)));
	const phoneEpDevice = epDevices.find((epDevice) => (epDevice.hasEpWithClusterId(DeviceType.DFFAD.clusterId)));
	if (phoneEpDevice) {
		const cluster = phoneEpDevice.getClusterByCapAndClusterId(DeviceType.DFFAD.cap, DeviceType.DFFAD.clusterId);
		const trackLocation = cluster?.[ClusterConstants.DFFAD.Attributes.TrackLocation] ?? false;
		const phoneMode = cluster?.[ClusterConstants.DFFAD.Attributes.PhoneMode];
		if (trackLocation && phoneMode === 2) { // 2 = realtime
			return true;
		}
	}
	return false;
};

const enableDisableGateway = async (gateway: Gateway, geofenceAllowed: boolean): Promise<void> => {
	const geofenceExists = await BackgroundGeolocation.geofenceExists(gateway.id);
	if (geofenceAllowed && !geofenceExists) {
		const newGeofence = await makeGeofence(gateway.id, gateway.latitude!, gateway.longitude!, gateway.geofence_radius);
		if (newGeofence) {
			await enableDisableGeofence([newGeofence]);
		}
	} else if (!geofenceAllowed && geofenceExists) {
		await BackgroundGeolocation.removeGeofence(gateway.id);
		await enableDisableGeofence([]);
	}
};

export const checkGeofencesGatewayInfo = async (gateways: Array<Gateway>): Promise<void> => {
	try {
		let geofences = [] as Array<Geofence>;
		try {
			geofences = await BackgroundGeolocation.getGeofences();
		} catch (error) {
			console.warn("Exception in 'BackgroundGeolocation.getGeofences'", error);
		}

		const allowedGateways = gateways.filter((gateway) => (gateway.geofence && isGatewayGeofenceReady(gateway)));
		const delGeofs = geofences.filter(({ identifier }) => (!allowedGateways.some(({ id }) => (id === identifier))));

		const newGeofsPromises = allowedGateways.filter((gateway) => (
			!geofences.some(({ identifier }) => (identifier === gateway.id))
		)).map((gateway) => (
			makeGeofence(gateway.id, gateway.latitude!, gateway.longitude!, gateway.geofence_radius)
		));

		const changedGeofsPromises = allowedGateways.filter((gateway) => {
			const geofence = geofences.find(({ identifier }) => (identifier === gateway.id));
			return geofence && (geofence.latitude !== gateway.latitude || geofence.longitude !== gateway.longitude || geofence.radius !== gateway.geofence_radius);
		}).map((gateway) => (
			makeGeofence(gateway.id, gateway.latitude!, gateway.longitude!, gateway.geofence_radius)
		));

		const newGeofs = (await Promise.all(newGeofsPromises)).filter((geofence) => (geofence)) as Array<Geofence>;
		const changedGeofs = (await Promise.all(changedGeofsPromises)).filter((geofence) => (geofence)) as Array<Geofence>;
		const addGeof = [...newGeofs, ...changedGeofs];

		delGeofs.forEach(async ({ identifier }) => {
			await BackgroundGeolocation.removeGeofence(identifier);
		});

		if (addGeof.length + delGeofs.length > 0) {
			await enableDisableGeofence(addGeof);
		}
	} catch (error) {
		console.error("checkGeofencesGatewayInfo error", error);
	}
};

export const checkGeofenceGateway = async (gateway: Gateway, devices?: DeviceObjs): Promise<void> => {
	try {
		let geofenceAllowed = false;
		if (isGatewayGeofenceReady(gateway)) {
			if (!devices) {
				try {
					const devicesData = await fetchDevicesFromGateway(gateway.id);
					devices = devicesData.map((deviceData) => (new Device(gateway.id, deviceData)));
				} catch (error) {
					console.warn("checkGeofenceGateway fetchDevicesFromGateway failed", error, gateway.id);
				}
			}
			if (devices) {
				const deviceId = await getDeviceId();
				const device = devices.find((device) => (device.id === deviceId));
				if (device) {
					geofenceAllowed = isDeviceGeofencePhoneModeRealtime(device);
				}
			}
		}
		await enableDisableGateway(gateway, geofenceAllowed);
	} catch (error) {
		console.error("checkGeofenceGateway error", error);
	}
};

export const checkGeofenceGatewayRemoved = async (gatewayId: GatewayId): Promise<void> => {
	try {
		if (await BackgroundGeolocation.geofenceExists(gatewayId)) {
			await BackgroundGeolocation.removeGeofence(gatewayId);
			await enableDisableGeofence([]);
		}
	} catch (error) {
		console.error("checkGeofenceGatewayRemoved error", error);
	}
};

export const checkGeofenceGatewayChanged = async (gatewayId: GatewayId, latitude?: number, longitude?: number, radius?: number): Promise<void> => {
	try {
		const oldGeofence = await BackgroundGeolocation.getGeofence(gatewayId);
		const changedGeofence = await makeGeofence(gatewayId, latitude ?? oldGeofence.latitude, longitude ?? oldGeofence.longitude, radius ?? oldGeofence.radius);
		if (changedGeofence) {
			await enableDisableGeofence([changedGeofence]);
		}
	} catch (error) {
		// ignored, because there is not geofence set to change
	}
};

export const checkGeofenceDeviceAddedUpdated = async (device: DeviceObj): Promise<void> => {
	try {
		const deviceId = await getDeviceId();
		if (device.id === deviceId) {
			const gateway = Gateways.getGateways().find(({ id }) => (id === device.gwId));
			if (gateway) {
				const geofenceAllowed = isGatewayGeofenceReady(gateway) ? isDeviceGeofencePhoneModeRealtime(device) : false;
				await enableDisableGateway(gateway, geofenceAllowed);
			}
		}
	} catch (error) {
		console.error("checkGeofenceDeviceAddedUpdated error", error);
	}
};

// Used for Devicechanges that are not on the selectedGateway
export const checkGeofenceDeviceAddedUpdatedExternal = async (gatewayId: GatewayId, deviceId: DeviceId, trackLocation: boolean, phoneMode: PhoneMode | undefined): Promise<void> => {
	try {
		const capDeviceId = await getDeviceId();
		if (deviceId === capDeviceId) {
			const gateway = Gateways.getGateways().find(({ id }) => (id === gatewayId));
			if (gateway) {
				const geofenceAllowed = isGatewayGeofenceReady(gateway) && trackLocation && phoneMode === 2; // 2 = realtime
				await enableDisableGateway(gateway, geofenceAllowed);
			}
		}
	} catch (error) {
		console.error("checkGeofenceDeviceAddedUpdatedExternal error", error);
	}
};

export const checkGeofenceDeviceDeleted = async (gatewayId: GatewayId, deviceId: DeviceId): Promise<void> => {
	try {
		const capDeviceId = await getDeviceId();
		if (deviceId === capDeviceId) {
			const gateway = Gateways.getGateways().find(({ id }) => (id === gatewayId));
			if (gateway) {
				await checkGeofenceGatewayRemoved(gateway.id);
			}
		}
	} catch (error) {
		console.error("checkGeofenceDeviceDeleted error", error);
	}
};

export const restartGeofencePlugin = async (): Promise<void> => {
	try {
		const { enabled } = await BackgroundGeolocation.getState();
		if (enabled) {
			await BackgroundGeolocation.stop();
		}
		const geofs = await BackgroundGeolocation.getGeofences();
		await enableDisableGeofence(geofs); // This sends exit events and enter events by readding all geofences
	} catch (error) {
		console.warn("Error while trying to restartGeofencePlugin");
	}
};
